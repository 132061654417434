html.theme-dark {
  $gray-dark:                 #333333;
  $gray:                      #828282;
  $gray-light:                #cccccc;

  $link-color:                #199ED7;
  $link-color-darkbg:         #6DCEF8;
  $color-base:                #FFFFFA;

  background: #202124;
  color: $color-base;

  main, body {
    background: #202124;
  }

  ul.nav li a {
    color: $link-color;
  }

  footer a.name-link {
    color: $color-base;
  }

  .logo-img, #scroll-arrow-top img {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
  }
}
