body {
  background-color: #FFFFFA;
}

img {
  max-width: 100%;
}

header {
  margin-top: 12px;
  
  ul.nav {
    margin-bottom: 60px;
  }

  hr.header-line {
    border-color: $gray-light;
    margin: 2.25em 0;
  }
}

header.header-short {
  margin-bottom: 65px;

  hr.header-line {
    margin: 1.8em 0;
  }
}

footer {
  padding-bottom: 100px;

  a.name-link {
    color: $gray-dark;
  }
}

.padder-margin {
  margin-bottom: 180px;
}

.padder-resume {
  margin-bottom: 60px;
}

.container-inset {

}

.home {
  margin: 0 auto;

  .row [class^="col"] {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.scroll-arrow {
  width: 100%;
  position: fixed;
  z-index: 9999;

  img {
    margin: 0 auto;
    width: 30px;
  }

  a {
    padding: 16px 14px;
    border-radius: 100px;
    opacity: 0.2;
    transition: ease-in-out all 0.15s;

    &:hover {
      background: $gray-light;
      opacity: 1.0;
    }
  }

  &.scroll-arrow-down {
    bottom: 48px;
    text-align: center;
  }

  &.scroll-arrow-top {
    display: none;
    bottom: 24px;
    right: 24px;
    text-align: right;
  }
}

img.full {
  width: 100%;
  max-width: 100%;
}


@-webkit-keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.0;}
}

span.typed-cursor {
  color: $link-color;
  font-weight: 100;
  text-decoration: blink;
	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.5s;
	-webkit-animation-iteration-count:infinite;
	-webkit-animation-timing-function:ease-in-out;
	-webkit-animation-direction: alternate;
}

.typed-headline {
  min-height: 200px;

  a {
    color: $color-base;

    &:hover {
      color: $link-color;
    }
  }
}
