// brz4 Style Variables
//-------------------------


// Colors
//

$gray-dark:                 #333333;
$gray:                      #828282;
$gray-light:                #cccccc;

$link-color:                #199ED7;
$link-color-darkbg:         #6DCEF8;
$color-base:                $gray-dark;

// Dark Theme


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:    "europa", -apple-system, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:     Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:          $font-family-sans-serif !default;

$font-size-base:            1.25rem !default;    // this assumes 20px base (1.25em @ 16px)
$font-size-lg:              1.8rem !default;
$font-size-sm:              1rem !default;
$font-size-xs:              .868rem !default;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;

$font-height: 1.5;


// Responsive
//

$width: 96%;
$gutter: 4%;
$breakpoint-sm: 26.25em; // 540px
$breakpoint-md: 45em; // 720px
$breakpoint-lg: 64em; // 1024px
$breakpoint-xl: 85.375em; // 1366px
