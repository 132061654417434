/**
* Set up a decent box model on the root element
*/

html {
  box-sizing: border-box;
  color: $color-base;
}

/**
* Make all elements from the DOM inherit from the parent box-sizing
* Since `*` has a specificity of 0, it does not override the `html` value
* making all elements inheriting from the root box-sizing value
* See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/
*, ::before, ::after {
  box-sizing: inherit;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}


/* ===== Initializr Styles ==================================================
 Author: Jonathan Verrecchia - verekia.com/initializr/responsive-template
 ========================================================================== */

body {
  font-size: $font-size-base;
  font-family: $font-family-sans-serif;
  line-height: $font-height;
}

.wrapper {
  width: 90%;
  margin: 0 5%;
}

/* ===================
  ALL: Orange Theme
 =================== */

.header-container {
  border-bottom: 20px solid #e44d26;
}

.footer-container,
.main aside {
  border-top: 20px solid #e44d26;
}

.header-container,
.footer-container,
.main aside {
  background: #f16529;
}

.title {
  color: white;
}

/* ==============
  MOBILE: Menu
 ============== */

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

nav a {
  display: block;
  margin-bottom: 10px;
  padding: 15px 0;

  text-align: center;
  text-decoration: none;
  font-weight: bold;

  color: white;
  background: #e44d26;
}

nav a:hover,
nav a:visited {
  color: white;
}

nav a:hover {
  text-decoration: underline;
}

/* ==============
  MOBILE: Main
 ============== */

.main {
  //padding: 30px 0;
}

.main article h1 {
  font-size: 2em;
}

.main aside {
  color: white;
  padding: 0px 5% 10px;
}

.footer-container footer {
  color: white;
  padding: 20px 0;
}

/* ===============
  ALL: IE Fixes
 =============== */

.ie7 .title {
  padding-top: 20px;
}

/* ==========================================================================
 Author's custom styles
 ========================================================================== */















/* ==========================================================================
 Media Queries
 ========================================================================== */

@media only screen and (min-width: 480px) {

/* ====================
  INTERMEDIATE: Menu
 ==================== */

  nav a {
      float: left;
      width: 27%;
      margin: 0 1.7%;
      padding: 25px 2%;
      margin-bottom: 0;
  }

  nav li:first-child a {
      margin-left: 0;
  }

  nav li:last-child a {
      margin-right: 0;
  }

/* ========================
  INTERMEDIATE: IE Fixes
 ======================== */

  nav ul li {
      display: inline;
  }

  .oldie nav a {
      margin: 0 0.7%;
  }
}

@media only screen and (min-width: 768px) {

/* ====================
  WIDE: CSS3 Effects
 ==================== */

  .header-container,
  .main aside {
      -webkit-box-shadow: 0 5px 10px #aaa;
         -moz-box-shadow: 0 5px 10px #aaa;
              box-shadow: 0 5px 10px #aaa;
  }

/* ============
  WIDE: Menu
 ============ */

  .title {
      float: left;
  }

  nav {
      float: right;
      width: 38%;
  }

/* ============
  WIDE: Main
 ============ */

  .main article {
      float: left;
      width: 57%;
  }

  .main aside {
      float: right;
      width: 28%;
  }
}

@media only screen and (min-width: 1140px) {

/* ===============
  Maximal Width
 =============== */

  .wrapper {
      width: 1026px; /* 1140px - 10% for margins */
      margin: 0 auto;
  }
}

/* ==========================================================================
 Helper classes
 ========================================================================== */

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* ==========================================================================
 Print styles
 ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
  }

  a,
  a:visited {
      text-decoration: underline;
  }

  a[href]:after {
      content: " (" attr(href) ")";
  }

  abbr[title]:after {
      content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
      content: "";
  }

  pre,
  blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
  }

  thead {
      display: table-header-group;
  }

  tr,
  img {
      page-break-inside: avoid;
  }

  img {
      max-width: 100% !important;
  }

  p,
  h2,
  h3 {
      orphans: 3;
      widows: 3;
  }

  h2,
  h3 {
      page-break-after: avoid;
  }
}
