.card-work {
  margin-bottom: 60px;

  .type-label {
    opacity: 0;
    transition: ease-in-out all 0.15s;
  }
  a:hover {
    .type-label {
      opacity: 1;
      transition: ease-in-out all 0.15s;
    }
  }
  a:hover h4 {
    color: $link-color;
    transition: ease-in-out all 0.15s;
  }

  .card-title {
    margin: 28px;
    h4 {
      color: $color-base;
      display: inline-block;
      transition: ease-in-out all 0.15s;
    }
  }
}

.stripe {
  width: 100%;

  // theming for blocks:

  &.stripe-seafoam {
    background: linear-gradient(210.41deg, #B7F4E6 0%, #76E4CB 100%);
  }
  &.stripe-pink {
    background: linear-gradient(210.34deg, #F5D3FC 0%, #F4C0FF 100%);
  }
  &.stripe-peach {
    background: linear-gradient(210.35deg, #FFE9B6 0%, #FFCF80 100%);
  }
  &.stripe-sky {
    background: linear-gradient(210.35deg, #A9E9FF 0%, #80E0FF 100%);
  }
  &.stripe-gray {
    background: linear-gradient(210.35deg, #ededed 0%, #F4F6F7 100%);
  }

  &.stripe-black {
    background: #282828; //#202020;

    h1, h2, h3, h4, h5, p {
      color: #FFFFFF;
    }

    a {
      color: $link-color;
    }

    .sub-header {
      color: #9B9B9B;
    }
  }
}

.stripe-page {
  h1 {
    line-height: normal;
  }
  .sub-header {
    font-size: $font-size-base * 1.2;
    margin: 0px;
    font-weight: $font-weight-light;
  }
}

.stripe-home {
  height: 350px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.2);
}

// TYPE LABELS
// Used for pills on work blocks
.type-label {
  padding: 2px 8px;
  background-color: $gray-light;
  color: #FFF;
  border-radius: 50px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;

  &.label-seafoam {
    background-color: #76E4CB;
    color: #FFF;
  }
  &.label-sky {
    background-color: #80E0FF;
    color: #FFF;
  }
  &.label-peach {
    background-color: #FFCF80;
    color: #FFF;
  }
}


// PADDERS
// padder class is used for applying padding and margin - must always be nested inside some block element, like .stripe

.home-padder-margin, .padder-margin-bottom {
  margin-bottom: 180px;
}

.padder-flush {
  padding: 0px;

  img {
    width: 100%;
  }
}

.padder-page {
  padding: 90px 0px;
}

.padder-condensed {
  padding: 100px 40px;
}
.padder-calm {
  padding: 120px 80px;
}




// Photography Section on Front Page
.photog {
  background-image: url("/assets/images/photo_ggb.webp");
  background-size: cover;
  padding-top: 130px;

  // theming
  &.dark {
    h1, h2, h3, h4, h5 {
      color: #FFFFFF;
    }
    a {
      color: $link-color-darkbg;

      &:hover {
        color: darken($link-color-darkbg, 25%);
      }
    }
  }
}

.slick-photog img {
  border-top: solid 4px #FFFFFF;
  border-bottom: solid 4px #FFFFFF;
  border-left: solid 2px #FFFFFF;
  border-right: solid 2px #FFFFFF;
  height: 250px;
}


// Work Pages
.hero img {
  max-width: 100%;
  width: 100%;
}

.work-header {
  margin-bottom: 180px;
}

.i_flow_right {
  width: 35px;
  height: 35px;
}

#index-work-blocks:focus {
  outline:none;
}

// Responsive Overrides

// Large widths (landscape tablet, web)
@media (min-width: $breakpoint-md) {
  .work-header {
    margin-bottom: 220px;
  }
}


// Special Pages
.ipad-left {
  background-image: url("/assets/images/work_assets/lotus/ipad_white.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 10% 18% 10% 0%;
}
.ipad-right {
  background-image: url("/assets/images/work_assets/lotus/ipad_black.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 10% 0% 10% 18%;
}
video#lotusvid-left {
  width: 100%;
}
video#lotusvid-right {
  width: 100%;
}


// Styles for scaling background cards on front page

.scale-parent {
  margin: 20px 0px;
  overflow: hidden;
  position: relative;
  float: left;
  display: inline-block;
  cursor: pointer;
}

.scale-child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}
.scale-parent:hover .scale-child, .scale-parent:focus .scale-child {
  -ms-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
}

.scale-parent:hover .scale-child:before, .scale-parent:focus .scale-child:before {
  display: block;
}

.scale-parent:hover a, .scale-parent:focus a {
  display: block;
}

.scale-child:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Several different images */
.bg-innov_suite {background-image: url("/assets/images/work_assets/innovation_suite/hero-innovationstudio.webp");}
.bg-lotus {background-image: url("/assets/images/work_assets/lotus/hero-lotus.webp");}
.bg-smart_it {background-image: url("/assets/images/work_assets/smart_it/hero-smartit.webp");}
.bg-concerto2 {background-image: url("/assets/images/work_assets/concerto2/hero-concerto2.webp");}
.bg-particle {background-image: url("/assets/images/work_assets/particle/hero-particle.webp");}
.bg-dem {background-image: url("/assets/images/work_assets/dem/hero-dem.webp");}
.bg-dash_studio {background-image: url("/assets/images/work_assets/dash_studio/hero-dashstudio.webp");}
