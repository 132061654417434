.slick-cards-image {
  transition: all 300ms ease;
  margin: 120px;

  img {
    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.25);
    max-height: 400px;
  }

  &.slick-center {
    transform: scale(1.4);
  }
}

.slick-slide {
  padding: 12px;

  img {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
  }
}

@media (max-width: $breakpoint-sm) {
  .slick-cards-image img {
    max-width: 240px;
  }
}

@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .slick-cards-image img {
    max-width: 400px;
  }
}

@media only screen and (min-width: $breakpoint-lg) {
  .slick-cards .slick-track {
    margin-left: -225px;
  }
}
