h1 {
  font-size: $font-size-base * 2.986; // 59.72px
  line-height: 1.2em;
}
h2 {
  font-size: $font-size-base * 2.488; // 49.76px
  line-height: 1.2em;
}
h3 {
  font-size: $font-size-base * 2.074; // 41.48
  line-height: 1.2em;
}
h4 {
  font-size: $font-size-base * 1.728; // 34.56px
  line-height: 1.2em;
}
h5 {
  font-size: $font-size-base * 1.44;  // 28.8px
  line-height: 1.2em;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0;
  padding: 0.5em 20px;

  &:before {
    color: #ccc;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  p {
    display: inline;
  }

  &.code {
    font-family: $font-family-monospace;
    font-size: 0.8em;
  }
}

.sub-header {
  color: $gray;
  font-weight: $font-weight-light;
  margin: 0px 0px 8px 0px;
}

.caption {
  font-size: $font-size-sm;
  margin: 8px 0px;
  color: $gray;
}

a {
  color: $link-color;
  text-decoration: none;
  transition: ease-in-out all 0.15s;

  &:hover {
    color: darken($link-color, 15%);
    transition: ease-in-out all 0.15s;

    &.underline {
      border: 0;
    }

    &.underline-hover {
      border-bottom: solid 1px darken($link-color, 15%);
    }
  }

  &.neutral {
    color: $color-base;

    &:hover {
      color: darken($color-base, 15%);
      transition: ease-in-out all 0.15s;
    }
  }

  &.underline {
    border-bottom: solid 1px $link-color;
    transition: ease-in-out all 0.15s;
  }
}

a.circle-icon {
  width: 28px;
  height: 28px;

  img {
    width: 28px;
    height: 28px;
  }
}

p {
  margin-top: 0px;
  margin-bottom: 1.65em;
}

.font-light {
  font-weight: $font-weight-light !important;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}

.font-gray-dark {
  color: $gray-dark;
}

.font-gray {
  color: $gray;
}

.font-uppercase {
  text-transform: uppercase;
}

.margin-none {
  margin: 0px;
}

.margin-top-none {
  margin-top: 0px;
}

.margin-top-sm {
  margin-top: 8px;
}

.margin-bottom-none {
  margin-bottom: 0px;
}

.margin-bottom-sm {
  margin-bottom: 8px;
}
.margin-bottom-md {
  margin-bottom: 24px;
}
