@keyframes bounce {
  0%, 10%, 30%, 50%, 80%, 100% {
    opacity: 1;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  5% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  15% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.bounce {
  -moz-animation: bounce 6s infinite;
  -webkit-animation: bounce 6s infinite;
  animation: bounce 6s infinite;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}



.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}


// Responsive Utilities

.hidden-sm {
  @media (max-width: $breakpoint_sm) {
    @include responsive-invisibility();
  }
}
.hidden-md {
  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    @include responsive-invisibility();
  }
}
.hidden-lg {
  @media (min-width: $breakpoint-md) {
    @include responsive-invisibility();
  }
}

.resp-img {
  max-width: 100%;
}
