ul.nav {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    display: inline;
    margin: 0 35px;

    a {
      color: $gray-dark;
      display: inline-block;
    }
  }

  li.active a {
    border-bottom: solid 1px $gray-light;
    font-weight: $font-weight-heavy;
  }

  &.right {
    float: right;
  }
}

ul.nav {
  li a {
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
  }
}

.link-home {

  > img {
    transition: ease-in-out all 0.35s;
  }

  &:hover > img {
    transform: rotate(20deg);
  }
}
