// Responsive Behavior

// Small widths (smartphone)
@media (max-width: $breakpoint-sm) {
  // downsize all headers and paragraph text
  body {
    font-size: 1.1rem;
  }

  h1 {
    font-size: 3rem;
    line-height: 1.1em;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.1em;
  }

  h3 {
    font-size: 2rem;
    line-height: 1.1em;
  }

  h4 {
    font-size: 1.7rem;
    line-height: 1.1em;
  }

  .typed-headline {
    font-size: 2rem;
    min-height: 300px;
  }

  header {
    margin-bottom: 60px;

    h4 {
      margin: 60px 0px 120px 0px;
    }

    .typed-headline {
      min-height: 300px;
    }
  }

  .home-padder-margin, .padder-margin-bottom {
    margin-bottom: 100px;
  }

  .card-work {
    margin-bottom: 60px;
  }

  .padder-margin {
    margin-bottom: 80px;
  }

  .padder-calm, .padder-condensed {
    padding: 60px 0px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    text-align: center;
  }

  .slick-prev, .slick-next {
    top: 75%;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-slide {
    .caption {
      min-height: 150px;
    }
  }

  .slick-cards {
    .slick-prev, .slick-next {
      top: 50%;
    }
  }

  footer {
    margin-top: 120px;

    ul.nav.right {
      float: none;
      margin-top: 50px;

      li {
        display: block;
        text-align: center;
        margin: 12px 0px;
      }
    }
  }
}

// Medium widths (portrait tablet, large phone)
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
  header {
    margin-bottom: 60px;

    h4 {
      margin: 60px 0px;
    }
  }

  .home-padder-margin, .padder-margin-bottom {
    margin-bottom: 120px;
  }

  .padder-margin {
    margin-bottom: 100px;
  }

  .card-work {
    margin-bottom: 100px;
  }

  .scroll-arrow {
    bottom: 24px;
  }

  .photog {
    min-height: 500px;
  }

  footer {
    ul.nav.right {
      margin-top: 50px;

      li {
        margin: 12px;
      }
    }
  }
}

// Large widths (landscape tablet, web)
@media (min-width: $breakpoint-lg) {
  header {
    margin-top: 20px;

    ul.nav {
      margin-bottom: 100px;
    }

    h4 {
      margin: 72px 0px;
    }

    h5 {
      width: 80%;
    }
  }

  .photog {
    min-height: 850px;
  }

  .container-inset {
    width: 52%;
  }

  .home {
    width: 500px;
  }
}
