@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "/assets/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/assets/images/" !default;
$slick-arrow-color: $gray !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  z-index: 999;
  position: absolute;
  display: block;
  height: 75px;
  width: 75px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: ease-in all 0.1s;

  background-color: rgba(225,225,225,0.6);
  border-radius: 100px;

  > img {
    opacity: 0.7;
    width: 15px;
    height: 26px;
    margin: 24px 30px;
  }

  &:hover, &:focus {
    background-color: rgba(225,225,225,0.8);
    transition: ease-out all 0.1s;

    > img {
      opacity: 1;
    }
  }

  &.slick-disabled {
    opacity: 0;
  }
}

.slick-prev {
  left: 25px;

  > img {
    margin: 24px 32px 24px 28px;
  }
}

.slick-next {
  right: 25px;

  > img {
    margin: 24px 28px 24px 32px;
  }
}
